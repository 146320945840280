<template>
  <main class="kb-main" id="kb-helpdesk-hrdt">
    <lxp-main-header :show-breadcrumb="true"/>
    <!-- main-content -->
    <div class="main-content">
      <!-- hrdt-container -->
      <div class="hrdt-container">
        <!-- hrdt-section-team -->
        <div class="hrdt-section hrdt-section-team">
          <div class="min-component">
            <!-- hrdt-article:01 -->
            <div class="hrdt-article hrdt-article-01">
              <!-- hrdt-section > hrdt-content -->
              <div class="hrdt-content">
                <div class="content-header">
                  <p class="subtitle">KB HR부(인재개발)는</p>
                  <h3 class="title">자율적인 학습문화를 만듭니다</h3>
                </div>
                <div class="content-description">
                  <p class="text">제 몫을 다하고 스스로 학습하는 문화 정착을 위한 상시학습 프로그램 및 자기주도 학습 지원 프로그램 운영, 새로운 학습경험을 통한 학습 동기부여를
                    마련합니다.</p>
                </div>
              </div>
              <!-- hrdt-section > hrdt-image -->
              <div class="hrdt-image">
                <img src="../../../assets/lxp/images/helpdesk/img_hrdt_01.jpg" alt=""/>
              </div>
            </div>
            <!-- hrdt-article:02 -->
            <div class="hrdt-article hrdt-article-02">
              <!-- hrdt-section > hrdt-content -->
              <div class="hrdt-content">
                <div class="content-header">
                  <p class="subtitle">KB HR부(인재개발)는</p>
                  <h3 class="title">변화에 유연하게 대응합니다</h3>
                </div>
                <div class="content-description">
                  <p class="text">디지털 전환 등 급격한 금융환경 변환에 대처하고, 전문성을 확보하기 위한 미래 성장 분야 우수인재 육성 프로그램을 운영합니다.</p>
                </div>
              </div>
              <!-- hrdt-section > hrdt-image -->
              <div class="hrdt-image">
                <img src="../../../assets/lxp/images/helpdesk/img_hrdt_02.jpg" alt=""/>
              </div>
            </div>
            <!-- hrdt-article:03 -->
            <div class="hrdt-article hrdt-article-03">
              <!-- hrdt-section > hrdt-content -->
              <div class="hrdt-content">
                <div class="content-header">
                  <p class="subtitle">KB HR부(인재개발)는</p>
                  <h3 class="title">기본에 충실하게 준비합니다</h3>
                </div>
                <div class="content-description">
                  <p class="text">경영철학 가치공유를 통한 바람직한 KB人의 Mind Set, ‘KB다움’ 문화확산 및 바람직한 리더십 역할 함양을 위해 노력합니다.</p>
                </div>
              </div>
              <!-- hrdt-section > hrdt-image -->
              <div class="hrdt-image">
                <img src="../../../assets/lxp/images/helpdesk/img_hrdt_03.jpg" alt=""/>
              </div>
            </div>
            <!-- hrdt-article:04 -->
            <div class="hrdt-article hrdt-article-04">
              <!-- hrdt-section > hrdt-content -->
              <div class="hrdt-image">
                <img src="../../../assets/lxp/images/helpdesk/img_hrdt_04.jpg" alt=""/>
              </div>
              <!-- hrdt-section > hrdt-content -->
              <div class="hrdt-content" style="margin-top: 32px;">
                <div class="content-header">
                  <h3 class="title">함께, 다 함께</h3>
                </div>
                <div class="content-description">
                  <p class="text">학습의 즐거움을 함께, 다 함께 누리고, 모든 직원의 성장과
                    발전, 개인의 목표를 위해 나아가는데 디딤돌이 되겠습니다.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- hrdt-section-location -->
        <div class="hrdt-section hrdt-section-location min-component">
          <div class="location-container">
            <div class="location-tabs">
              <nav class="kb-nav-round-tabs">
                <ul class="kb-nav-list">
                  <li class="kb-nav-item">
                    <button @click="clickTab('a')" class="kb-nav-link" :class="{'is-active' : trnct.value === 'a'}">
                      <span class="kb-nav-text">여의도</span>
                    </button>
                  </li>
                  <li class="kb-nav-item">
                    <button @click="clickTab('b')" class="kb-nav-link" :class="{'is-active' : trnct.value === 'b'}">
                      <span class="kb-nav-text">천안 연수원</span>
                    </button>
                  </li>
                  <li class="kb-nav-item">
                    <button @click="clickTab('c')" class="kb-nav-link" :class="{'is-active' : trnct.value === 'c'}">
                      <span class="kb-nav-text">일산 연수원</span>
                    </button>
                  </li>
                  <li class="kb-nav-item">
                    <button @click="clickTab('d')" class="kb-nav-link" :class="{'is-active' : trnct.value === 'd'}">
                      <span class="kb-nav-text">속초 연수원</span>
                    </button>
                  </li>
                  <li class="kb-nav-item">
                    <button @click="clickTab('e')" class="kb-nav-link" :class="{'is-active' : trnct.value === 'e'}">
                      <span class="kb-nav-text">대천 연수원</span>
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
            <div class="location-maps">
              <div class="map">
                <img :src="trnct.src" />
              </div>
              <dl class="map-content">
                <dl class="content content-top">
                  <dt class="content-title">{{ trnct.tab }}</dt>
                  <dd class="content-text">미래를 이끌어갈 인재 양성의 산실 국민은행 연수원에서 여러분의 비전을 마음껏 펼쳐보세요.</dd>
                </dl>
                <dl class="content">
                  <dt class="content-title">주소</dt>
                  <dd class="content-text">{{ trnct.address }}</dd>
                  <dt class="content-title">전화</dt>
                  <dd class="content-text">{{ trnct.tel }}</dd>
                  <dt v-if="trnct.value === 'a'" class="content-title">팩스</dt>
                  <dd v-if="trnct.value === 'a'" class="content-text">{{ trnct.fax }}</dd>
                </dl>
              </dl>
            </div>
          </div>
        </div>
      </div>
      <!-- //hrdt-container -->
    </div>
    <!-- //main-content -->
  </main>
</template>

<script>
import LxpMainHeader from '@/components/common/LxpMainHeader';
import {ref} from "vue";
export default {
  name: 'HelpHrdIntro',
  components: {LxpMainHeader},
  setup() {

    const trnct = ref({});
    const trnctList = ref([
      {label: '여의도', tab: '여의도', value: 'a', address: '서울 영등포구 의사당대로 141   우)07332',
        tel: '02) 2073-5352, 5368, 5364', fax: '02) 2073-5369', src: require('../../../assets/lxp/images/helpdesk/trnct_yeouido.jpg')},
      {label: 'KB국민은행천안연수원', tab: '천안 연수원', value: 'b', address: '충남 천안시 동남구 문암로 283   우)31065',
        tel: '02) 2073-5352, 5368, 5364', fax: '', src: require('../../../assets/lxp/images/helpdesk/trnct_cheonan_1.jpg')},
      {label: 'KB국민은행일산연수원', tab: '일산 연수원', value: 'c', address: '경기 고양시 일산동구 탄중로 393-66    우)10330',
        tel: '02) 2073-7878', fax: '', src: require('../../../assets/lxp/images/helpdesk/trnct_ilsan.jpg')},
      {label: 'KB금융그룹속초연수원', tab: '속초 연수원', value: 'd',  address: '강원 속초시 미시령로 3287   우)24812',
        tel: '033)635-2281', fax: '', src: require('../../../assets/lxp/images/helpdesk/trnct_sokcho4.jpg')},
      {label: 'KB국민은행대천연수원', tab: '대천 연수원', value: 'e', address: '충남 보령시 해수욕장3길 23   우)33487',
        tel: '041)932-4911', fax: '', src: require('../../../assets/lxp/images/helpdesk/trnct_daecheon.jpg')},
    ])

    const clickTab = (val) => {
      trnct.value = trnctList.value.filter(x => x.value == val)[0]
    }

    clickTab('a');

    return{
      trnctList,
      trnct,
      clickTab
    }
  }
};
</script>